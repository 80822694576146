import BEbug from "../images/bayequity_circle_mark.png";

/**
 * Return an array of blogs owned by the Loan Officer
 * @param {Object[]} edges
 */
export const mapBlogs = (edges = []) => {
    return edges.map(edge => {
        let {
            node: {
                id,
                html,
                slug,
                title,
                date,
                author,
                feature,
                bloghero,
                image,
                categories,
                tags,
            },
        } = edge;

        if (author === null) {
            author = Object.assign({}, corporateAuthor);
        }

        let { photo } = author.gallery;

        return {
            photo,
            id,
            slug,
            html,
            title,
            author,
            date,
            feature,
            bloghero,
            image,
            categories,
            tags,
        };
    });
};

export const corporateAuthor = {
    slug: "/bay-equity-news",
    gallery: {
        photo: BEbug,
    },
    profile: {
        name: "Bay Equity",
        branch: {
            title: "Corporate News",
        },
    },
};
