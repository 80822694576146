import React, { Component } from "react";
import { uniqBy } from "lodash";
import Accent from "../Accent";
import LinkButton from "./../LinkButton";
import BlogFallBack1 from "../../images/BlogFallBack1.jpg";
import BlogFallBack2 from "../../images/BlogFallBack2.jpg";
import BlogFallBack3 from "../../images/BlogFallBack3.jpg";

import { blogTile } from "../../lib/gtm";
import { corporateAuthor } from "../../lib/utility";

import styles from "./styles.module.less";

export default class BlogRoll extends Component {
    renderBlogs = blog => {
        //get date

        let d = new Date(blog.date.toString());
        let date = d.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
        });

        let { bloghero, image } = blog;
        let img =
            image ||
            bloghero ||
            [BlogFallBack1, BlogFallBack2, BlogFallBack3][
                Math.floor(Math.random() * 3)
            ];
        const { photoAlignment = "center" } = blog;
        let backgroundPosition = photoAlignment || "top";
        let photoStyle = {
            backgroundImage: `url("${img}")`,
            backgroundPosition,
        };

        const { slug, title, author = corporateAuthor } = blog;
        let theAuthor = Object.assign({}, author, corporateAuthor);

        //parse owner image
        let bug = theAuthor.gallery.photo;
        let hashtag = `#${theAuthor.profile.name}`;
        let { name } = theAuthor.profile;
        let bugIsIcon = bug.includes("bayequity_circle_mark") ? true : false;

        return (
            <a
                href={slug}
                className={styles.post}
                key={blog.id}
                data-gtm={blogTile}>
                <div
                    className={styles.photo}
                    data-gtm={blogTile}
                    style={photoStyle}>
                    <span className={styles.tag} data-gtm={blogTile}>
                        {hashtag}
                    </span>
                    <img
                        className={bugIsIcon ? styles.icon : styles.photo}
                        data-blink-src={bug}
                        alt={name}
                        data-gtm={blogTile}
                    />
                </div>
                <div className={styles.content} data-gtm={blogTile}>
                    <div className={styles.date} data-gtm={blogTile}>
                        {date}
                    </div>
                    <div className={styles.title} data-gtm={blogTile}>
                        {title}
                    </div>
                </div>
            </a>
        );
    };
    render() {
        const {
            blogs,
            corporateBlogs,
            word,
            amount = 3,
            title,
            slug = "",
            bg = "light",
            url = "",
        } = this.props;

        let authorSlug = slug.replace(/\//g, "");

        let duplicates = blogs.concat(corporateBlogs);
        //remove duplicates
        let items = uniqBy(duplicates, "id");

        if (!items.length) return null;

        return (
            <section className={styles.BlogRoll} data-background={bg}>
                <div className={styles.inner}>
                    <div className={styles.blogRollContainer}>
                        <h1 className={styles.title}>
                            {!!title ? (
                                <>{title}</>
                            ) : (
                                <>
                                    There’s always more
                                    <br />
                                    on {word} blog
                                </>
                            )}
                        </h1>
                        <Accent align="left" />
                        <div className={styles.posts}>
                            {items.slice(0, amount).map(this.renderBlogs)}
                        </div>

                        <div className={styles.cta}>
                            <LinkButton
                                href={
                                    url
                                        ? `/bay-equity-news/${url}`
                                        : `/bay-equity-news/archive/?author=${authorSlug}`
                                }
                                label="View All Blogs"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
